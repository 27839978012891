var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "white--text mb-4",
                      attrs: { color: "secondary" },
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          " Upload " +
                            _vm._s(
                              this.$router.history.current.params.attachment ==
                                "Receipt"
                                ? "Proof Of Payment"
                                : this.$router.history.current.params.attachment
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c("v-file-input", {
                        attrs: {
                          rules: _vm.rules,
                          accept: "image/png, image/jpeg , .pdf",
                          color: "primary",
                          outlined: "",
                          multiple: "",
                          "prepend-icon": "",
                          label: "Please choose the files",
                          counter: "",
                          "show-size": 1000,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (ref) {
                              var index = ref.index
                              var text = ref.text
                              return [
                                index < 2
                                  ? _c(
                                      "v-chip",
                                      {
                                        attrs: {
                                          color: "deep-purple accent-4",
                                          dark: "",
                                          label: "",
                                          small: "",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(text) + " ")]
                                    )
                                  : index == 2
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-overline grey--text text--darken-3",
                                      },
                                      [
                                        _vm._v(
                                          " +" +
                                            _vm._s(_vm.files.length - 2) +
                                            " Files(s) "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.files,
                          callback: function ($$v) {
                            _vm.files = $$v
                          },
                          expression: "files",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "mx-3" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              border: "2px dashed grey",
                              width: "100%",
                              height: "200px",
                            },
                            style: { borderColor: _vm.isHoverColor },
                            attrs: { id: "drop_zone" },
                            on: {
                              dragover: [
                                function ($event) {
                                  return _vm.dragOverHandler()
                                },
                                function ($event) {
                                  $event.preventDefault()
                                },
                              ],
                              drop: _vm.dropHandler,
                              dragenter: function ($event) {
                                $event.preventDefault()
                              },
                              dragleave: _vm.onDragLeave,
                            },
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "justify-center ",
                                staticStyle: { "align-items": "center" },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-center pt-5 mt-5",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        style: { color: _vm.isHoverColor },
                                        attrs: { size: "50px" },
                                      },
                                      [_vm._v(" bi-upload ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "d-flex justify-center align-center",
                                        style: { color: _vm.isHoverColor },
                                      },
                                      [
                                        _vm._v(
                                          " Drag and drop one or more files here. "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  this.$router.history.current.params.attachment == "Refund"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-6" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  "auto-grow": "",
                                  label:
                                    "Please write your remark ( Optional )",
                                  dense: "",
                                  width: "auto",
                                  height: "auto",
                                },
                                model: {
                                  value: _vm.remark,
                                  callback: function ($$v) {
                                    _vm.remark = $$v
                                  },
                                  expression: "remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "pa-2 d-flex justify-end mt-5 mb-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.api.isLoading,
                          },
                          on: { click: _vm.validateInput },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                  _c("div", [_c("AError", { attrs: { api: this.api } })], 1),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }